<template>
  <div class="isRight">
    <div class="container">
      <div class="correct" v-if="answer == 1">
        <img src="@/assets/image/other/congratulation.png" alt="" />
        <p>正确，继续加油！</p>
      </div>
      <div class="error" v-else>
        <img src="@/assets/image/other/error.png" alt="" />
        <p style="color: #f01d10">错误，继续加油！</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ['answer'],
  methods: {},
  beforeDestroy() {},
  mounted() {
    console.log();
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .isRight {
    height: 100%;
    display: table;
    font-family: 'SYHeiBold';
    margin: 0 auto;
    .container {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      img {
        width: 210px;
        height: 210px;
        margin-top: -50px;
      }
      p {
        font-size: 60px;
        line-height: 48px;
        color: #5f9160;
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .isRight {
    height: 100%;
    display: table;
    font-family: 'SYHeiBold';
    margin: 0 auto;
    .container {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      img {
        width: 273px;
        height: 273px;
        margin-top: -65px;
      }
      p {
        font-size: 78px;
        line-height: 62px;
        color: #5f9160;
      }
    }
  }
}
</style>
