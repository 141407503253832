<template>
  <div class="delay">
    <div class="container">
      <Practice v-if="isPractice && !showAns"></Practice>
      <isRight :answer="correct_type" v-if="showAns"></isRight>
      <div class="delayBox" v-if="!showAns">
        <div class="top">
          <img
            v-show="showTop"
            v-if="delayList[this.current].topItem"
            :src="delayList[this.current].topItem.src"
            alt=""
          />
          <div class="border" v-show="showTop || !showOption"></div>
        </div>
        <ul class="options">
          <li
            v-for="(item, index) in delayList[this.current].options"
            :key="index"
            @click="choose(item)"
            @mouseover="mouseover(index)"
            @mouseout="mouseout"
            class="optionItem"
          >
            <img v-show="showOption" :src="item.src" alt="" />
            <div :class="hoverIndex == index ? 'border' : ''"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Practice from '@/components/practice.vue';
import isRight from '@/components/isRight.vue';
import { saveSubjectiveAns } from '@/api/index';
//数据
let practiceData = [
  {
    id: '01',
    options: [
      {
        id: '011',
        src: require('@/assets/image/delay/test/trial1-1.png'),
      },
      {
        id: '012',
        src: require('@/assets/image/delay/test/trial1-2.png'),
      },
      {
        id: '013',
        src: require('@/assets/image/delay/test/trial1-3.png'),
      },
      {
        id: '014',
        src: require('@/assets/image/delay/test/trial1-4.png'),
      },
    ],
  },
  {
    id: '02',
    options: [
      {
        id: '021',
        src: require('@/assets/image/delay/test/trial2-1.png'),
      },
      {
        id: '022',
        src: require('@/assets/image/delay/test/trial2-2.png'),
      },
      {
        id: '023',
        src: require('@/assets/image/delay/test/trial2-3.png'),
      },
      {
        id: '024',
        src: require('@/assets/image/delay/test/trial2-4.png'),
      },
    ],
  },
  {
    id: '03',
    options: [
      {
        id: '031',
        src: require('@/assets/image/delay/test/trial3-1.png'),
      },
      {
        id: '032',
        src: require('@/assets/image/delay/test/trial3-2.png'),
      },
      {
        id: '033',
        src: require('@/assets/image/delay/test/trial3-3.png'),
      },
      {
        id: '034',
        src: require('@/assets/image/delay/test/trial3-4.png'),
      },
    ],
  },
  {
    id: '04',
    options: [
      {
        id: '041',
        src: require('@/assets/image/delay/test/trial4-1.png'),
      },
      {
        id: '042',
        src: require('@/assets/image/delay/test/trial4-2.png'),
      },
      {
        id: '043',
        src: require('@/assets/image/delay/test/trial4-3.png'),
      },
      {
        id: '044',
        src: require('@/assets/image/delay/test/trial4-4.png'),
      },
    ],
  },
];
let formalData = [
  {
    id: '11',
    options: [
      {
        id: '111',
        src: require('@/assets/image/delay/block1/trial1-1.png'),
      },
      {
        id: '112',
        src: require('@/assets/image/delay/block1/trial1-2.png'),
      },
      {
        id: '113',
        src: require('@/assets/image/delay/block1/trial1-3.png'),
      },
      {
        id: '114',
        src: require('@/assets/image/delay/block1/trial1-4.png'),
      },
    ],
  },
  {
    id: '12',
    options: [
      {
        id: '121',
        src: require('@/assets/image/delay/block1/trial2-1.png'),
      },
      {
        id: '122',
        src: require('@/assets/image/delay/block1/trial2-2.png'),
      },
      {
        id: '123',
        src: require('@/assets/image/delay/block1/trial2-3.png'),
      },
      {
        id: '124',
        src: require('@/assets/image/delay/block1/trial2-4.png'),
      },
    ],
  },
  {
    id: '13',
    options: [
      {
        id: '131',
        src: require('@/assets/image/delay/block1/trial3-1.png'),
      },
      {
        id: '132',
        src: require('@/assets/image/delay/block1/trial3-2.png'),
      },
      {
        id: '133',
        src: require('@/assets/image/delay/block1/trial3-3.png'),
      },
      {
        id: '134',
        src: require('@/assets/image/delay/block1/trial3-4.png'),
      },
    ],
  },
  {
    id: '14',
    options: [
      {
        id: '141',
        src: require('@/assets/image/delay/block1/trial4-1.png'),
      },
      {
        id: '142',
        src: require('@/assets/image/delay/block1/trial4-2.png'),
      },
      {
        id: '143',
        src: require('@/assets/image/delay/block1/trial4-3.png'),
      },
      {
        id: '144',
        src: require('@/assets/image/delay/block1/trial4-4.png'),
      },
    ],
  },
  {
    id: '21',
    options: [
      {
        id: '211',
        src: require('@/assets/image/delay/block2/trial1-1.png'),
      },
      {
        id: '212',
        src: require('@/assets/image/delay/block2/trial1-2.png'),
      },
      {
        id: '213',
        src: require('@/assets/image/delay/block2/trial1-3.png'),
      },
      {
        id: '214',
        src: require('@/assets/image/delay/block2/trial1-4.png'),
      },
    ],
  },
  {
    id: '22',
    options: [
      {
        id: '221',
        src: require('@/assets/image/delay/block2/trial2-1.png'),
      },
      {
        id: '222',
        src: require('@/assets/image/delay/block2/trial2-2.png'),
      },
      {
        id: '223',
        src: require('@/assets/image/delay/block2/trial2-3.png'),
      },
      {
        id: '224',
        src: require('@/assets/image/delay/block2/trial2-4.png'),
      },
    ],
  },
  {
    id: '23',
    options: [
      {
        id: '231',
        src: require('@/assets/image/delay/block2/trial3-1.png'),
      },
      {
        id: '232',
        src: require('@/assets/image/delay/block2/trial3-2.png'),
      },
      {
        id: '233',
        src: require('@/assets/image/delay/block2/trial3-3.png'),
      },
      {
        id: '234',
        src: require('@/assets/image/delay/block2/trial3-4.png'),
      },
    ],
  },
  {
    id: '24',
    options: [
      {
        id: '241',
        src: require('@/assets/image/delay/block2/trial4-1.png'),
      },
      {
        id: '242',
        src: require('@/assets/image/delay/block2/trial4-2.png'),
      },
      {
        id: '243',
        src: require('@/assets/image/delay/block2/trial4-3.png'),
      },
      {
        id: '244',
        src: require('@/assets/image/delay/block2/trial4-4.png'),
      },
    ],
  },
  {
    id: '31',
    options: [
      {
        id: '311',
        src: require('@/assets/image/delay/block3/trial1-1.png'),
      },
      {
        id: '312',
        src: require('@/assets/image/delay/block3/trial1-2.png'),
      },
      {
        id: '313',
        src: require('@/assets/image/delay/block3/trial1-3.png'),
      },
      {
        id: '314',
        src: require('@/assets/image/delay/block3/trial1-4.png'),
      },
    ],
  },
  {
    id: '32',
    options: [
      {
        id: '321',
        src: require('@/assets/image/delay/block3/trial2-1.png'),
      },
      {
        id: '322',
        src: require('@/assets/image/delay/block3/trial2-2.png'),
      },
      {
        id: '323',
        src: require('@/assets/image/delay/block3/trial2-3.png'),
      },
      {
        id: '324',
        src: require('@/assets/image/delay/block3/trial2-4.png'),
      },
    ],
  },
  {
    id: '33',
    options: [
      {
        id: '331',
        src: require('@/assets/image/delay/block3/trial3-1.png'),
      },
      {
        id: '332',
        src: require('@/assets/image/delay/block3/trial3-2.png'),
      },
      {
        id: '333',
        src: require('@/assets/image/delay/block3/trial3-3.png'),
      },
      {
        id: '334',
        src: require('@/assets/image/delay/block3/trial3-4.png'),
      },
    ],
  },
  {
    id: '34',
    options: [
      {
        id: '341',
        src: require('@/assets/image/delay/block3/trial4-1.png'),
      },
      {
        id: '342',
        src: require('@/assets/image/delay/block3/trial4-2.png'),
      },
      {
        id: '343',
        src: require('@/assets/image/delay/block3/trial4-3.png'),
      },
      {
        id: '344',
        src: require('@/assets/image/delay/block3/trial4-4.png'),
      },
    ],
  },
  {
    id: '41',
    options: [
      {
        id: '411',
        src: require('@/assets/image/delay/block4/trial1-1.png'),
      },
      {
        id: '412',
        src: require('@/assets/image/delay/block4/trial1-2.png'),
      },
      {
        id: '413',
        src: require('@/assets/image/delay/block4/trial1-3.png'),
      },
      {
        id: '414',
        src: require('@/assets/image/delay/block4/trial1-4.png'),
      },
    ],
  },
  {
    id: '42',
    options: [
      {
        id: '421',
        src: require('@/assets/image/delay/block4/trial2-1.png'),
      },
      {
        id: '422',
        src: require('@/assets/image/delay/block4/trial2-2.png'),
      },
      {
        id: '423',
        src: require('@/assets/image/delay/block4/trial2-3.png'),
      },
      {
        id: '424',
        src: require('@/assets/image/delay/block4/trial2-4.png'),
      },
    ],
  },
  {
    id: '43',
    options: [
      {
        id: '431',
        src: require('@/assets/image/delay/block4/trial3-1.png'),
      },
      {
        id: '432',
        src: require('@/assets/image/delay/block4/trial3-2.png'),
      },
      {
        id: '433',
        src: require('@/assets/image/delay/block4/trial3-3.png'),
      },
      {
        id: '434',
        src: require('@/assets/image/delay/block4/trial3-4.png'),
      },
    ],
  },
  {
    id: '44',
    options: [
      {
        id: '441',
        src: require('@/assets/image/delay/block4/trial4-1.png'),
      },
      {
        id: '442',
        src: require('@/assets/image/delay/block4/trial4-2.png'),
      },
      {
        id: '443',
        src: require('@/assets/image/delay/block4/trial4-3.png'),
      },
      {
        id: '444',
        src: require('@/assets/image/delay/block4/trial4-4.png'),
      },
    ],
  },
  {
    id: '51',
    options: [
      {
        id: '511',
        src: require('@/assets/image/delay/block5/trial1-1.png'),
      },
      {
        id: '512',
        src: require('@/assets/image/delay/block5/trial1-2.png'),
      },
      {
        id: '513',
        src: require('@/assets/image/delay/block5/trial1-3.png'),
      },
      {
        id: '514',
        src: require('@/assets/image/delay/block5/trial1-4.png'),
      },
    ],
  },
  {
    id: '52',
    options: [
      {
        id: '521',
        src: require('@/assets/image/delay/block5/trial2-1.png'),
      },
      {
        id: '522',
        src: require('@/assets/image/delay/block5/trial2-2.png'),
      },
      {
        id: '523',
        src: require('@/assets/image/delay/block5/trial2-3.png'),
      },
      {
        id: '524',
        src: require('@/assets/image/delay/block5/trial2-4.png'),
      },
    ],
  },
  {
    id: '53',
    options: [
      {
        id: '531',
        src: require('@/assets/image/delay/block5/trial3-1.png'),
      },
      {
        id: '532',
        src: require('@/assets/image/delay/block5/trial3-2.png'),
      },
      {
        id: '533',
        src: require('@/assets/image/delay/block5/trial3-3.png'),
      },
      {
        id: '534',
        src: require('@/assets/image/delay/block5/trial3-4.png'),
      },
    ],
  },
  {
    id: '54',
    options: [
      {
        id: '541',
        src: require('@/assets/image/delay/block5/trial4-1.png'),
      },
      {
        id: '542',
        src: require('@/assets/image/delay/block5/trial4-2.png'),
      },
      {
        id: '543',
        src: require('@/assets/image/delay/block5/trial4-3.png'),
      },
      {
        id: '544',
        src: require('@/assets/image/delay/block5/trial4-4.png'),
      },
    ],
  },
];
export default {
  data() {
    return {
      isPractice: true, //是否练习题
      showTop: false,
      showOption: false,
      showAns: false,
      delayList: [], //数据
      current: 0, //当前进度
      original: [], //原始数据
      startTime: 0, //起始时间
      endTime: 0, //结束时间
      startTimeTotal: 0, //起始总时间
      endTimeTotal: 0, //结束总时间
      hoverIndex: null,
      answerCount: 0, //作答数量
      rightCount: 0, //正确数量
      errorCount: 0, //错误数量
      overtimeCount: 0, //超时未答数量
      timer: null,
      totalTime: 0, //用时
      timers: null,
      timer1: null,
      timer2: null,
      isIE: false, //浏览器
      correct_type: '',
    };
  },
  components: { Practice, isRight },
  watch: {
    showTop() {
      if (!this.isIE) {
        const element = document.querySelector('.top');
        element.classList.add(
          'animate__animated',
          'animate__flipInY',
          'animate__faster'
        );
        setTimeout(() => {
          element.classList.remove(
            'animate__animated',
            'animate__flipInY',
            'animate__faster'
          );
        }, 1000);
      }
    },
    showOption() {
      if (!this.isIE) {
        const element = document.querySelectorAll('.optionItem');
        element.forEach((item, index) => {
          element[index].classList.add(
            'animate__animated',
            'animate__flipInY',
            'animate__faster'
          );
        });
        setTimeout(() => {
          element.forEach((item, index) => {
            element[index].classList.remove(
              'animate__animated',
              'animate__flipInY',
              'animate__faster'
            );
          });
        }, 1000);
      }
    },
    current() {
      this.progress();
    },
  },
  methods: {
    mouseover(index) {
      if (this.showOption) {
        this.hoverIndex = index;
      }
    },
    mouseout() {
      this.hoverIndex = null;
    },
    //进行中
    progress() {
      let options = this.delayList[this.current].options;
      let topItem = options[Math.floor(Math.random() * options.length)];
      this.delayList[this.current].topItem = topItem;
      this.$forceUpdate();
      this.original.push({
        id: this.delayList[this.current].id,
        type: this.delayList[this.current].type,
        correct_answer: topItem.id,
      });
      if (this.delayList[this.current].type == 1) {
        //无延迟
        this.showTop = true;
        this.showOption = true;
        this.startTime = this.$util.getTimestamp();
        this.progressNext();
      } else {
        this.timer = setTimeout(() => {
          this.showTop = true;
          setTimeout(() => {
            this.showTop = false;
            if (this.delayList[this.current].type == 2) {
              //0试次
              this.showOption = true;
              this.startTime = this.$util.getTimestamp();
              this.progressNext();
            }
            if (this.delayList[this.current].type == 3) {
              //4试次
              setTimeout(() => {
                this.showOption = true;
                this.startTime = this.$util.getTimestamp();
                this.progressNext();
              }, 4000);
            }
            if (this.delayList[this.current].type == 4) {
              //12试次
              setTimeout(() => {
                this.showOption = true;
                this.startTime = this.$util.getTimestamp();
                this.progressNext();
              }, 12000);
            }
          }, 3000);
        }, 1000);
      }
    },
    //进行中
    progressNext() {
      this.timers = setTimeout(() => {
        if (!this.original[this.current].answer) {
          this.original[this.current].answer = null;
          this.original[this.current].time = null;
          this.original[this.current].correct_type = 0;
          this.overtimeCount++;
          if (this.current >= this.delayList.length - 1) {
            this.finish();
          } else {
            this.showOption = false;
            this.showTop = false;
            this.hoverIndex = null;
            this.current++;
          }
        }
      }, 10000);
    },
    //选择
    choose(item) {
      if (this.showOption) {
        clearTimeout(this.timers);
        if (this.original[this.current].answer) {
          //如果已有答案，拦截
          return false;
        } else {
          this.showOption = false;
          this.endTime = this.$util.getTimestamp();
          this.original[this.current].answer = item.id;
          this.original[this.current].time = this.endTime - this.startTime;
          if (item.id == this.original[this.current].correct_answer) {
            this.original[this.current].correct_type = 1;
            this.rightCount++;
            // if (this.isPractice) {
            this.correct_type = 1;
            this.showAns = true;
            // }
          } else {
            this.original[this.current].correct_type = 0;
            this.errorCount++;
            // if (this.isPractice) {
            this.correct_type = 0;
            this.showAns = true;
            // }
          }
          this.answerCount++;
          setTimeout(() => {
            this.showAns = false;
            if (this.current >= this.delayList.length - 1) {
              //完成
              this.finish();
            } else {
              this.showTop = false;
              this.hoverIndex = null;
              this.current++;
            }
          }, 1000);
        }
      }
    },
    finish() {
      this.endTimeTotal = this.$util.getTimestamp();
      this.totalTime = this.endTimeTotal - this.startTimeTotal;
      if (this.isPractice) {
        //练习题
        // if (this.totalTime > 60000 - 1000) {
          this.$router.replace({
            path: '/practiceEnd',
            query: {
              source: 'delay',
            },
          });
        // } else {
        //   this.$router.replace({
        //     path: '/relax',
        //     query: {
        //       time: 60000 - this.totalTime,
        //       path: '/practiceEnd?source=delay',
        //     },
        //   });
        // }
      } else {
        //提交数据
        let query = {
          fmUserId: this.$util.getStorage('fmUserId'),
          fmProductQuestionId: '4',
          quesNum: '4-1',
          totalTime: this.totalTime > 280000 ? 280000 : this.totalTime,
          data1Name: 'rightCount',
          data1: this.rightCount,
          data2Name: 'errorCount',
          data2: this.errorCount,
          data3Name: 'answerCount',
          data3: this.answerCount,
          data4Name: 'overtimeCount',
          data4: this.overtimeCount,
          dataJason: JSON.stringify(this.original),
          remark:
            'data1:正确数量；data2:错误数量；data3:作答数量；data4:超时未答数量；id:图片编码；type:类型 1 无延迟，2 0s延迟，3 4s延迟，4 12s延迟；correct_answer：正确答案；answer：用户答案；time：用时ms；correct_type:是否正确，1正确，0错误；',
        };
        // console.log(query);
        saveSubjectiveAns(query)
          .then((res) => {
            if (res.code === 0) {
              //大题编号
              sessionStorage.setItem(
                'fmProductQuestionId',
                res.data.fmProductQuestionId
              );
              //小题编号
              sessionStorage.setItem('quesNum', res.data.quesNum);
              //是否完成
              sessionStorage.setItem('isFinished', res.data.isFinished);
              // if (this.totalTime > 280000 - 1000) {
                this.$router.replace({
                  path: '/getCard',
                  query: {
                    id: 4,
                    current: '4-1',
                  },
                });
              // } else {
              //   this.$router.replace({
              //     path: '/relax',
              //     query: {
              //       time: 280000 - this.totalTime,
              //       path: '/getCard?id=4&current=4-1',
              //     },
              //   });
              // }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    clearTimeout(this.timers);
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
  },
  created() {
    if (this.$route.query.practice) {
      this.isPractice = false;
    }
    if (this.isPractice) {
      let practiceInterval = this.$util.shuffle([1, 2, 3, 4]);
      practiceData = this.$util.shuffle(practiceData);
      practiceData.forEach((item, index) => {
        item.type = practiceInterval[index];
      });
      this.delayList = practiceData;
      this.timer1 = setTimeout(() => {
        this.finish();
      }, 60000);
    } else {
      //打乱block设计，4种情况随机
      let formalInterval = this.$util.shuffle([
        1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4,
      ]);
      formalData = this.$util.shuffle(formalData);
      formalData.forEach((item, index) => {
        item.type = formalInterval[index];
      });
      this.delayList = formalData;
      this.timer2 = setTimeout(() => {
        this.finish();
      }, 280000);
    }
  },
  mounted() {
    //是否ie
    if (!!window.ActiveXObject || 'ActiveXObject' in window) {
      this.isIE = true;
    }
    this.startTimeTotal = this.$util.getTimestamp();
    this.progress();
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .delay {
    font-family: 'SYHeiBold';
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    .container {
      .delayBox {
        .top {
          width: 160px;
          height: 160px;
          margin: 80px 0;
          display: inline-block;
          background-image: url('../../assets/image/delay/back.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: relative;
          // background-color: pink;
          .border {
            width: 139px;
            height: 139px;
            border: 4px solid #f01d10;
            position: absolute;
            top: 6px;
            left: 10px;
            border-radius: 28px;
          }
          img {
            width: 100%;
            height: 100%;
            // background: blue;
          }
        }
        .options {
          width: 686px;
          height: 206px;
          padding: 20px 0;
          // border: 8px solid #fce5b4;
          // background: #f7f7f7;
          // box-shadow: 0px 4px 16px rgba(136, 75, 0, 0.2);
          // border-radius: 16px;
          margin: 0 auto;
          text-align: center;
          li {
            width: 160px;
            height: 160px;
            display: inline-block;
            background-image: url('../../assets/image/delay/back.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
            // background-color: pink;
            position: relative;
            .border {
              width: 139px;
              height: 139px;
              border: 4px solid #25cf7e;
              position: absolute;
              top: 6px;
              left: 11px;
              border-radius: 28px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .delay {
    font-family: 'SYHeiBold';
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    .container {
      .delayBox {
        .top {
          width: 208px;
          height: 208px;
          margin: 104px 0;
          display: inline-block;
          background-image: url('../../assets/image/delay/back.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: relative;
          // background-color: pink;
          .border {
            width: 181px;
            height: 181px;
            border: 5px solid #f01d10;
            position: absolute;
            top: 8px;
            left: 13px;
            border-radius: 36px;
          }
          img {
            width: 100%;
            height: 100%;
            // background: blue;
          }
        }
        .options {
          width: 892px;
          height: 268px;
          padding: 26px 0;
          // border: 10px solid #fce5b4;
          // background: #f7f7f7;
          // box-shadow: 0px 4px 16px rgba(136, 75, 0, 0.2);
          // border-radius: 16px;
          margin: 0 auto;
          text-align: center;
          li {
            width: 208px;
            height: 208px;
            display: inline-block;
            background-image: url('../../assets/image/delay/back.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
            // background-color: pink;
            position: relative;
            .border {
              width: 181px;
              height: 181px;
              border: 4px solid #25cf7e;
              position: absolute;
              top: 8px;
              left: 14px;
              border-radius: 28px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
